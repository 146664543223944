import type { JSONSchemaType } from 'ajv';

export const strictStringSchema: JSONSchemaType<string> = {
  type: 'string',
};

export const nullableStringSchema: JSONSchemaType<string> = {
  type: 'string',
  nullable: true,
};
